<template>
  <transition name="slide">
    <div class="subpage">
      <PageText>
        <template v-slot:title>{{ $route.meta.title }}</template>
        <template v-slot:subtitle v-if="$route.meta.subtitle">{{ $route.meta.subtitle }}</template>
        <template v-slot:text>
          <p class="page-text__text">
            "Radość umacnia serca i pomaga wytrwać w dobru. Jest drogą do doskonałości – najkrótszą i najpewniejszą. Im
            więcej w nas radości, tym bliżej nam do świętości."
          </p>
          <p class="page-text__text">
            Osobowość Św. Filipa ukształtowała się we Florencji. Urodził się w 1515r. Był człowiekiem wrażliwym na piękno,
            szanującym drugiego człowieka, cechował się prostotą oraz niezwykłym humorem. Prowadził życie religijne, lubił
            wychodzić poza miasto i tam modlić się w samotności. Chciał zostać z zawodu kupcem jednak w miarę upływu czasu w
            jego sercu coraz bardziej dojrzewała myśl by całkowicie oddać się Bogu. Filip Neri sprzedał wszystkie swoje
            książki z wyjątkiem Pisma Świętego oraz Sumy Teologicznej św. Tomasza z Akwinu a zebrane pieniądze rozdał ubogim.
            Pragnął opiekować się żyjącymi w nędzy ludźmi. Opiekował się także opuszczoną młodzieżą. Organizował Oratoria,
            które gromadziły młodych na modlitwie, katechezie, wykładach, koncertach.
          </p>
          <p class="page-text__text">
            Nawet w trudnych sytuacjach życiowych nie był człowiekiem smutnym. Świętego Filipa nazywamy często radosnym
            świętym. Żył zgodnie z wezwaniem św. Pawła: Radujcie się zawsze w Panu! To właśnie św. Filip po tym jak
            zaproponowano mu godność biskupią, odmówił wykrzykując „Preferisco il Paradiso”, czyli „Wolę raj, wolę niebo”, co
            stało się dla nas inspiracją do nazwania spotkań młodych w Gostyniu - Paradiso.
          </p>
          <p class="page-text__text">
            Mieszkańcy Florencji ofiarowali Filipowi rzymski kościół pw. Św. Jana, przy którym gromadzili się duchowni
            synowie zwani oratorianami a w późniejszym czasie filipinami. Św. Filip zmarł 26 maja 1595 r.
          </p>
          <div class="btn" style="display: flex">
            <router-link to="/patroni">
              <Btn text="Powrót" :reverse="true" />
            </router-link>
          </div>
        </template>
      </PageText>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
};
</script>
<style lang="scss" scoped>
</style>
